function getBrowserInfo()
{
var agent = navigator.userAgent.toLowerCase() ;
window.navigator.appName.indexOf("Microsoft") != -1
var regStr_ie = /msie [\d.]+;/gi ;
var regStr_ff = /firefox\/[\d.]+/gi
var regStr_chrome = /chrome\/[\d.]+/gi ;
var regStr_saf = /safari\/[\d.]+/gi ;
//IE
if(agent.indexOf("msie") > 0)
{
return agent.match(regStr_ie) ;
}
 
//firefox
else if(agent.indexOf("firefox") > 0)
{
return agent.match(regStr_ff) ;
}
 
//Safari
else if(agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0)
{
return agent.match(regStr_saf) ;
}
 
//Chrome
else if(agent.indexOf("chrome") > 0)
{
return agent.match(regStr_chrome) ;
}
 
}
export default getBrowserInfo
import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { uuid } from 'vue-uuid';
import md5 from 'js-md5';
import jsonBig from 'json-bigint'
import getBrowserInfo from '../utils/BrowserName'
import  aes from '../utils/aes'
import bd_decrypt from "../utils/autonavi"
const http = axios.create({
  baseURL:"http://api.fuhangbeiyue.com/",//"http://192.168.0.124:7001",//process.env.VUE_APP_BASEURL," http://api.fuhangbeiyue.com/"
  timeout:30000000,
  transformResponse: [function (data) {
    
    try {
      // 如果转换成功则返回转换的数据结果
     
      return jsonBig.parse(data)
      
    } catch (err) {
      // 如果转换失败，则包装为统一数据格式并返回
      return {
        data
      }
    }
  }]

})
http.interceptors.request.use(function (config) {
  NProgress.start()

  // 判断浏览器是否第一次登录
  if(window.localStorage.getItem('clientId')===null){
    window.localStorage.setItem("clientId", uuid.v4());
  }
  config.headers['Access-Control-Allow-Origin'] = '*'
const uuidr=uuid.v1()

const clientId=window.localStorage.getItem('clientId')
const equipmentType=getBrowserInfo()[0]
const equipmentName=getBrowserInfo()[0].split("/")[0]
const requestTime=new Date().getTime()
config.headers.equipmentIdentification=uuidr
config.headers.clientId=clientId
config.headers.equipmentType=equipmentType
config.headers.equipmentName=equipmentName
config.headers.requestTime = `${requestTime}`
config.headers.sign=md5(`${uuidr}#${equipmentName}#${equipmentType}#${md5(uuidr.slice(7,26))}#${ parseInt(requestTime/1000/180)}`)

config.headers.Authorization= window.sessionStorage.getItem('accessToken')
//测试环境不验签直接过
config.headers.testToken="2gq72h2qrbhx256y0167uf5wd64ls55u"
// 测试环境响应不加密
config.headers.BG_DEBUG=0
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});
// 响应拦截
http.interceptors.response.use(function (config){

  NProgress.done()

// aes解密
  // config.data=JSON.parse(aes.decrypt(config.data))
  return config
})
export default http
<template>
  <el-container class="home-container">
    <!--头部-->
    <el-header>
      <div >
        <div :class="isCollapse ? 'headeropcr' : 'headeropc'">
          <img v-if="!isCollapse" style="margin: -2px 0 0 22px; width: 35px; height: 35px" src="../assets/img/组397@2x.fb25bfc8.png"  alt=""/>
          <img style="width: 40px; height: 40px; margin: -2px 0 0 10px" v-if="isCollapse"  src="../assets/img/组397@2x.fb25bfc8.png" alt="" />
          <span v-else-if="!isCollapse">富航生活</span>
        </div>
        <div style="float: left; margin: 0 0 0 25px" slot="header">
          <i v-if="!isCollapse" @click="toggleCollapser()" style="font-size: 30px; color: #606266; float: left; margin: 0px 0 0;"  class="el-icon-s-fold">
          </i>
          <i v-if="isCollapse" @click="toggleCollapser()" style="font-size: 30px; color: #606266; float: left; margin: 0px 0 0;" class="el-icon-s-unfold" >
          </i>
        </div>
      </div>
      <el-button type="primary" class="logout" @click="logout" style="margin-right: 24px">退出</el-button>
    </el-header>
    <el-container>
      <!--侧边导航栏-->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <!-- <div class="toggle-button" @click="toggleCollapse">|||</div> -->
        <el-menu
          background-color="#1E7CEE"
          text-color=" #b1d4ff "
          active-text-color="#409efe"
          unique-opened
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          :default-active="activePath"
        >
       
        <!-- <el-submenu index="10" v-if="!Recruitment">
            <template slot="title">
              <i class="el-icon-s-home"></i>
              <span>首页</span>
            </template>
            <el-menu-item-group>
              <el-menu-item  index="/index" @click="saveNavActiveState('/index')">
                <i class="el-icon-s-grid"></i>
                <span>数据看板</span>
              </el-menu-item>
            
            </el-menu-item-group>
          </el-submenu> -->
          <el-submenu index="1" v-if="!Recruitment">
            <template slot="title">
              <i class="el-icon-goods"></i>
              <span>商品管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item  index="/classify" @click="saveNavActiveState('/classify')">
                <i class="el-icon-s-grid"></i>
                <span>商品管理</span>
              </el-menu-item>
            
            </el-menu-item-group>
          </el-submenu>
          
                <el-submenu index="9" v-if="!Recruitment">
            <template slot="title">
              <i class="el-icon-s-claim"></i>
              <span>订单管理</span>
            </template>
            <el-menu-item-group>
            <el-menu-item v-if="!isMerchant" index="/orderFormList" @click="saveNavActiveState('/orderFormList')">
                <i class="el-icon-s-grid"></i>
                <span>订单列表</span>
              </el-menu-item>
                    <el-menu-item v-if="isMerchant" index="/merchantFormList" @click="saveNavActiveState('/merchantFormList')">
                <i class="el-icon-s-grid"></i>
                <span>订单列表</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2" v-if="!Recruitment" >
            <template slot="title">
              <i class="el-icon-s-shop"></i>
              <span>店铺管理</span>
            </template>
            <el-menu-item-group >
              <el-menu-item index="/StoreList" @click="saveNavActiveState('/StoreList')" v-if="!isMerchant">
                <i class="el-icon-menu"></i>
                <span>店铺列表</span>
              </el-menu-item >
            
              <el-menu-item index="/StorList"  @click="saveNavActiveState('/StorList')" v-if="!isMerchant">
                <i class="el-icon-menu"></i>
                <span>商品分类</span>
              </el-menu-item>
                   <el-menu-item index="/contractList" @click="saveNavActiveState('/contractList')" v-if="!isMerchant">
                <i class="el-icon-menu"></i>
                <span>合同列表</span>
              </el-menu-item >
                <el-menu-item index="/updateBusinessInfo"  @click="saveNavActiveState('/updateBusinessInfo')" v-if="isMerchant">
                <i class="el-icon-menu"></i>
                <span>店铺设置</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
              <el-submenu index="8" v-if="!isMerchant&&!Recruitment">
            <template slot="title">
              <i class="el-icon-s-finance"></i>
              <span>财务管理</span>
            </template>
            <el-menu-item-group>
            <el-menu-item index="/Withdraw"  @click="saveNavActiveState('/Withdraw')" v-if="!isMerchant">
                <i class="el-icon-menu"></i>
                <span>提现列表</span>
            
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3" v-if="!isMerchant&&!Recruitment">
            <template slot="title">
              <i class="el-icon-s-marketing"></i>
              <span>运营管理</span>
            </template>
            
            <!-- <el-menu-item-group>
              <el-menu-item  index="/CustomerServiceNotline" @click="saveNavActiveState('/CustomerServiceNotline')">
                <i class="el-icon-menu"></i>
                <span>客服热线</span>
              </el-menu-item>
            </el-menu-item-group> -->
         
            <el-menu-item-group>
              <el-menu-item index="/FeedDack" @click="saveNavActiveState('/FeedDack')">
                <i class="el-icon-menu"></i>
                <span>意见反馈</span>
              </el-menu-item>
              <el-menu-item index="/Rebate" @click="saveNavActiveState('/Rebate')">
                <i class="el-icon-menu"></i>
                <span>返利设置</span>
              </el-menu-item>
            </el-menu-item-group>
                 <el-menu-item-group>
              <el-menu-item index="/slideshow" @click="saveNavActiveState('/slideshow')">
                <i class="el-icon-menu"></i>
                <span>首页轮播图</span>
              </el-menu-item>
            </el-menu-item-group>
            <el-submenu index="3-1" v-if="!isMerchant&&!Recruitment">
              <template slot="title">
                <i class="el-icon-chat-square"></i>
                <span>服务通知</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/ServiceNotification"  @click="saveNavActiveState('/ServiceNotification')">
                  <i class="el-icon-menu"></i>
                  <span>服务通知内容</span>
                </el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group>
                <el-menu-item index="/ServiceType"  @click="saveNavActiveState('/ServiceType')">
                  <i class="el-icon-menu"></i>
                  <span >服务通知类型</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- <el-submenu index="3-2" v-if="!isMerchant">
              <template slot="title">
                <i class="el-icon-monitor"></i>
                <span>广告管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item  index="/content" @click="saveNavActiveState('/content')">
                  <i class="el-icon-menu"></i>
                  <span>广告内容</span>
                </el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group>
                <el-menu-item index="/location" @click="saveNavActiveState('/location')">
                  <i class="el-icon-menu"></i>
                  <span>广告位置</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu> -->
          </el-submenu>
          <el-submenu index="4" v-if="!isMerchant&&!Recruitment">
            <template slot="title">
              <i class="el-icon-s-custom"></i>
              <span>会员管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/memberList" @click="saveNavActiveState('/memberList')">
                <i class="el-icon-menu"></i>
                <span>会员列表</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          
          <!-- <el-submenu index="5" v-if="!isMerchant">
            <template slot="title">
              <i class="el-icon-truck"></i>
              <span>物流管理</span>
            </template>
            <el-menu-item index="/SFfreight"  @click="saveNavActiveState('/SFfreight')">
              <i class="el-icon-menu"></i>
              <span>顺丰运费</span>
            </el-menu-item>
          </el-submenu> -->
          <!-- 代驾管理 -->
          <!-- <el-submenu index="6" v-if="!isMerchant">
            <template slot="title">
              <i class="el-icon-bicycle"></i>
              <span>代驾管理</span>
            </template>                                        
            <el-menu-item  index="/BriverList" @click="saveNavActiveState('/BriverList')">
              <i class="el-icon-menu"></i>
              <span>代驾列表</span>
            </el-menu-item>
            <el-menu-item  index="/topup" @click="saveNavActiveState('/topup')">
              <i class="el-icon-menu"></i>
              <span>充值记录</span>
            </el-menu-item>
            <el-menu-item index="/OrderList" @click="saveNavActiveState('/OrderList')">
              <i class="el-icon-menu"></i>
              <span>订单列表</span>
            </el-menu-item>
            <el-menu-item index="/BillingList" @click="saveNavActiveState('BillingList')">
              <i class="el-icon-menu"></i>
              <span>计费设置</span>
            </el-menu-item>
          </el-submenu> -->
          <!-- <el-submenu index="7" v-if="!isMerchant">
            <template slot="title">
              <i class="el-icon-s-tools"></i>
              <span>权限管理</span>
            </template>
            <el-menu-item index="/Authority"  @click="saveNavActiveState('/Authority')">
              <i class="el-icon-menu"></i>
              <span>角色列表</span>
            </el-menu-item>
            <el-menu-item index="/AccountList" @click="saveNavActiveState('/AccountList')">
              <i class="el-icon-menu"></i>
              <span>账户列表</span>
            </el-menu-item>
          </el-submenu> -->
            <el-menu-item-group v-if="Recruitment">
              <el-menu-item index="/Recruitment" @click="saveNavActiveState('/Recruitment')">
                <i class="el-icon-menu"></i>
                <span>商家入驻</span>
              </el-menu-item>
            </el-menu-item-group>
        </el-menu>
      </el-aside>

      <!--主要内容区域-->
      <el-main style="z-index: 999; ">
        <!--渲染home子路由-->
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { getUserOfPermission } from "../api/index";
export default {
  name: "Home",
  data() {
    return {
      // 控制导航菜单的宽度
      isCollapse: false,
      accessToken: "",
      isMerchant: false,
      activePath:"",
      Recruitment:"",
    };
  },
  created() {
    this.isMerchant = window.sessionStorage.getItem("userIdentity")==1
    this.Recruitment= window.sessionStorage.getItem("Recruitment")
    if(this.Recruitment){
this.saveNavActiveState('/Recruitment')
    }
    this.accessToken = window.sessionStorage.getItem("accessToken");
   
  },

  methods: {

    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    // 返回首页
    backHome() {
      this.$router.push("/index");
      window.sessionStorage.removeItem("activePath");
    },

    toggleCollapser() {
      this.isCollapse = !this.isCollapse;
    },
    // 保存激活的导航地址到sessionStorage
    saveNavActiveState(activePath) {
      window.sessionStorage.setItem("activePath", activePath);
      this.activePath = activePath;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 103%;
  height: 50px;
  background: #fff;
  margin: -20px 0 0 -32px;
}

.logo {
  width: 300px;
  height: 70px;
  display: flex;
  align-items: center;

  background: #1e7cee;
  color: #ffffff;
  z-index: 1;
  font-weight: 700;
  img {
    margin-left: 10px;
  }
  span {
    font-size: 25px;
    margin: -2px 0 0 6px;
  }
}
#copwier {
  width: 40px;
  height: 40px;
  margin: 20px 0 0 10px;
}
#copwie {
  width: 30px;
  height: 30px;
  margin: 10px 0 0 70px;
}
.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
}
.el-submenu__title > i {
  font-size: 21px;
  color: rgb(177, 212, 255);
}
.home-container {
   -webkit-user-select:none;
    user-select:none;

  height: 100%;
  .el-header {
    background: #ffffff;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
      width: 300px;
      background: #fff;
      margin-left: -20px;
      height: 100%;
      .headeropc {
        display: flex;
        align-items: center;
        width: 200px;
        height: 100%;

        background: #1e7cee;
      }
      .headeropcr {
        display: flex;
        align-items: center;
        width: 64px;
        height: 100%;

        background: #1e7cee;
      }
      img {
        width: 40px;
        margin-left: 0px;
      }

      span {
        margin-left: 0px;
        font-size: 30px;
        font-weight: 700;
      
      
      }
    }
  }
span{
    -webkit-user-select:none;
    user-select:none;
}
  .el-aside {
    background: #1e7cee;

    .el-menu {
      border-right: 0;
    }
  }
}
</style>

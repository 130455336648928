
import Home from "@/views/Home";

export default {
  path: '/home',
  component: Home,
  redirect: '/index',
  children: [
    // 首页
    {
      path: '/index',
      component: () => import('../views/Recruitment.vue')
    },
  //  商家入驻
  {
    path: '/Recruitment',
    component: () => import('../views/Recruitment.vue')
  },
  /**  商品管理 -**/
    // 商品列表
     {
      path: '/classify',
      component: () => import('../views/CommodityManagement/ClassIfy.vue')
    },
         // 订单列表
       {
          path: '/orderFormList',
          component: () => import('../views/CommodityManagement/orderFormList.vue')
        },
             // 商家订单列表
       {
        path: '/merchantFormList',
        component: () => import('../views/CommodityManagement/merchantFormList.vue')
      },
   
  /**  会员管理  **/
  // 会员列表
 {
  path: '/memberList',
  component: () => import('../views/memberManagement/memberList.vue')
},
  /**  店铺管理 **/
  // 店铺列表
  {
    path: '/StoreList',
    component: () => import('../views/StoreManagement/StoreList.vue')
  },
    // 提现列表
    {
      path: '/Withdraw',
      component: () => import('../views/StoreManagement/Withdraw.vue')
    },
  // 店铺设置
  {
    path: '/updateBusinessInfo',
    component: () => import('../views/StoreManagement/updateBusinessInfo.vue')
  },
  // 平台商品分类
  {
    path: '/StorList',
    component: () => import('../views/StoreManagement/StorList.vue')
  },
   // 店铺商品分类
   {
    path: '/PlatformClassification',
    component: () => import('../views/StoreManagement/PlatformClassification.vue')
  },
     // 合同列表
     {
      path: '/contractList',
      component: () => import('../views/StoreManagement/contractList.vue')
    },
 
    /** 运营管理 **/
    // 首页轮播图
    {
      path: '/slideshow',
      component: () => import('../views/OperationsManagement/slideshow.vue')
    }, 
    // 意见反馈
    {
      path: '/FeedDack',
      component: () => import('../views/OperationsManagement/FeedDack.vue')
    }, 
    //返利设置
    {
      path: '/Rebate',
      component: () => import('../views/OperationsManagement/Rebate.vue')
    }, 
    
    // 客服热线
    {
      path: '/CustomerServiceNotline',
      component: () => import('../views/OperationsManagement/CustomerServiceNotline.vue')
    }, 
    // 广告内容
    {
      path: '/content',
      component: () => import('../views/OperationsManagement/content.vue')
    }, 
    //广告位置    
     {
      path: '/location',
      component: () => import('../views/OperationsManagement/location.vue')
    },
  //  服务通知类型
    {
      path: '/ServiceType',
      component: () => import('../views/OperationsManagement/ServiceType.vue')
    },
    //服务通知内容   
    {
      path: '/ServiceNotification',
      component: () => import('../views/OperationsManagement/ServiceNotification.vue')
    },
   
     /**物流管理**/
    //顺丰运费  
    {
      path: '/SFfreight',
      component: () => import('../views/FreightManagement/SFfreight.vue')
    },
      /**权限管理**/
    //角色列表  
    {
      path: '/Authority',
      component: () => import('../views/AuthorityManagement/Authority.vue')
    },
     //账户列表  
     {
      path: '/AccountList',
      component: () => import('../views/AuthorityManagement/AccountList.vue')
    },
    {
      path: '/Accou',
      component: () => import('../views/AuthorityManagement/Accou.vue')
    },

       /**代驾管理**/
      //  计费设置
       {
        path: '/BillingList',
        component: () => import('../views/Substitute/BillingList.vue')
      },
      //  代驾管理
      {
        path: '/BriverList',
        component: () => import('../views/Substitute/BriverList.vue')
      },
      //订单管理
      {
        path: '/OrderList',
        component: () => import('../views/Substitute/OrderList.vue')
      },
        //订单管理
        {
          path: '/topup',
          component: () => import('../views/Substitute/topup.vue')
        },
      
      
  ]
}
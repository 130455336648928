// 商城相关接口
import http from "../utils/aixos";
// 密码登录接口
export const getUserSignInDetailList = params => http.get('/api/user/login',{params})
// 获取验证码
export const sendShopLoginMsg = params => http.get('/api/platform/sendLoginVerificationCode',{params})
// 验证码
export const loginShopMsgForH5 = params => http.get('/api/user/verificationCodeLogin',{params})
// 用户签到列表
export const login = params => http.post('/fhlife/manage/user/login', params)
/*商品管理 */
// 编辑商品详情
export const updateGoods = params => http.post('/api/goods/updateGoods', params)
// 查询规格
export const searchGoodsSpecificationByGoodsId = params => http.get('/api/platform/searchGoodsSpecificationByGoodsId', {params})
// 删除商品规格
export const delGoodsSpecification = params => http.post('/api/platform/delGoodsSpecification', params)
// 添加商品规格
export const insertGoodsSpecification = params => http.post('/api/platform/insertGoodsSpecification', params)
// 编辑商品
export const updateGoodsSpecification = params => http.post('api/platform/updateGoodsSpecification', params)
// 商品列表
export const searchGoodsList = params => http.post('/api/goods/searchGoodsList', params)
// 上架商品
export const insertGoods = params => http.post('/api/goods/insertGoods', params)
/*会员管理 */
// 手机查询用户详情
export const getUserByMobile= params => http.get('/api/user/getUserByMobile', {params})
// 用户列表
export const getPage = params => http.get('/api/user/searchUserInfoList', {params})
// 用户详情
export const userparticulars = params => http.get('/api/user/get', {params})
// 修改用户信息
export const adminUpdate = params => http.post('/api/user/adminUpdate', params)

/*商品列表*/
// 删除商品
export const delGoods = params => http.post('/api/goods/delGoods', params)
export const searchGoodsById = params => http.get('/api/goods/searchGoodsById',{params})
// 商家接单 
export const acceptOrder = params => http.get('/api/order/acceptOrder',{params})
// 订单列表
export const searchOrderList = params => http.get('/api/order/searchOrderList',{params})
// 订单详情
export const searchOrderById = params => http.get('/api/order/searchOrderById',{params})
export const updateGoodStatus = params => http.get('/api/goods/updateGoodStatus',{params})
// 退款订单详情
export const searchOrderRefundRecordById = params => http.get('/api/pay/searchOrderRefundRecordById',{params})
// 退款审核
export const shopApplyOrderRefund = params => http.post('/api/pay/shopApplyOrderRefund', params)

/*店铺管理*/
// 提现列表
export const searchUserWithdrawList = params => http.post('/api/user/searchUserWithdrawList', params)
// 商家查询店铺订单
export const searchBussinessOrderListByStatus = params => http.get('/api/order/searchBussinessOrderListByStatus', {params})
// 商家列表
export const searchBusinessInfoList = params => http.get('/api/business/getBusinessInfoList', {params})
// 删除商家信息
export const delBusinessInfo = params => http.post("/api/business/delBusinessInfo",params)
// 获取合同列表
export const getContractListResult = params => http.get('/api/platform/getContractListResult',{params})
// 合同详情
export const contractViewPageURL = params => http.get('/api/platform/contractViewPageURL',{params})
// 合同下载
export const contractDownLoadPDF = params => http.get('/api/platform/contractDownLoadPDF',{params})
// 合同状态更改
export const updNoticeFlag = params => http.get('/fhlife/contract/updNoticeFlag',{params})
// 获取合同详情
export const getContractDetails = params =>http.get('/api/platform/getContractDetails',{params})
/* 店铺入驻*/
export const insertBusinessInfo = params =>http.post("/api/business/insertBusinessInfo",params)
// 店内分类列表
export const searchBusinessCategoryListForPC = params => http.get('/api/platform/searchBusinessCategoryListForPC', {params})
// 城市名获取分类
export const getGoodCategoryListByCityName = params => http.get('/api/platform/getGoodCategoryListByCityName', {params})
// 删除店内分类
export const delBusinessCategory = params =>http.post("/api/platform/delBusinessCategory",params)
// 添加店内分类
export const insertBusinessCategory = params =>http.post("/api/platform/insertBusinessCategory",params)
// 获取二级分类
export const getScondaryCategoryList = params => http.get('/api/platform/getScondaryCategoryList', {params})
// 取城市下的分类
export const getCategoryList = params => http.get('/api/platform/getCategoryList', {params})
// 添加排序
export const insertGoodCategoryForPC= params =>http.post("/api/platform/insertGoodCategoryForPC",params)
// 根据id查询分类详情
export const searchGoodCategoryById = params => http.get('/api/platform/searchGoodCategoryById', {params})
// 商家信息修改
export const updateBusinessInfo = params => http.post('/api/business/updateBusinessInfo', params)
// 获取店铺详情
export const searchBusinessInfoById = params => http.get('/api/business/searchBusinessInfoById', {params})
// 启用停用商铺
export const enableBusiness = params => http.get('/api/business/enableBusiness', {params})

/*商品分类模块*/
// 查询商品类别列表
export const getGoodCategoryListForPC = params => http.get('/api/platform/getGoodCategoryListForPC', {params})

/*权限功能*/
// 用户详情
export const searchUserInfoById = params => http.get('/api/user/searchUserInfoById', {params})
// 根据id查询店铺
export const searchBusinessInfoByUserId = params => http.get('/api/business/searchBusinessInfoByUserId', {params})
// 
export const getById = params => http.get('/api/user/role/getById',{params})
// 角色编辑
export const saveOrUpdateRole = params => http.post('/api/admin/saveOrUpdateRole',params)
// 角色列表
export const listRole = params => http.post("/api/admin/listRole",params)
// 角色删除
export const delRole = params => http.get("/api/admin/delRole",{params})
// 绑定角色
export const bindRole = params => http.get("/api/admin/bindRole",{params})
// 权限列表
export const listPermission = params => http.post("/api/admin/listPermission",params)

/**服务通知类型 */
// 消息类型列表
export const searchSystemMessageTypeList = params => http.get("/api/msg/searchSystemMessageTypeList",{params})
// 添加列表类型
export const insertSystemMessageType = params => http.post("/api/msg/insertSystemMessageType",params)
// 删除列表类型
export const delSystemMessageType= params => http.post("/api/msg/delSystemMessageType",params)
// id查询类型详情
export const searchSystemMessageTypeById = params => http.get("/api/msg/searchSystemMessageTypeById",{params})
// 编辑列表类型
export const updateSystemMessageType= params => http.post("/api/msg/updateSystemMessageType",params)
// 提现审核
export const handleWithdraw= params => http.post("/api/user/handleWithdraw",params)


/**运营管理  */ 
// 服务通知内容列表
export const searchSystemMessageList = params => http.get("/api/msg/searchSystemMessageList",{params})
// 添加服务通知内容
export const insertSystemMessage = params => http.post("/api/msg/insertSystemMessage",params)
// 删除用户消息内容
export const delSystemMessage = params =>http.post("/api/msg/delSystemMessage",params)
// 根据用户类型查询消息
export const getSystemMessageListByUserTypet = params => http.get("/api/msg/getSystemMessageListByUserType",{params})
// 修改用户类型内容
export const updateSystemMessage = params =>http.post("/api/msg/updateSystemMessage",params)
// 根据id查询服务内容详情
export const searchSystemMessageById = params => http.get("/api/msg/searchSystemMessageById",{params})
// 意见反馈
export const searchFeedbackList = params => http.get("/api/platform/searchFeedbackList",{params})
// 首页轮播图
export const searchActivityRecommendedList = params => http.get('/api/recommend/searchActivityRecommendedList',{params})
// 查询轮播图详情
export const searchActivityRecommendedById = params =>http.get('/api/recommend/searchActivityRecommendedById',{params})
// 添加首页轮播图
export const insertActivityRecommended = params => http.post('/api/recommend/insertActivityRecommended',params)
// 编辑首页轮播图
export const updateActivityRecommended = params => http.post('/api/recommend/updateActivityRecommended',params)
// 删除首页轮播图
export const delActivityRecommended = params => http.post('/api/recommend/delActivityRecommended',params)

// 省份
export const searchProvinceList = params => http.get("/api/platform/searchProvinceList",{params})
// 城市
export const searchCityList = params => http.get("/api/platform/searchCityList",{params})

/* 图片上传*/
export const uploadImages = params => http.post("/api/platform/uploadImages",params)
/* 视频上传*/
export const uploadVideo = params => http.post("/api/platform/uploadVideo",params)
// 返利列表
export const searchRebateList = params => http.get("/api/platform/searchServiceRatioList",{params})





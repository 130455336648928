import Vue from 'vue'
import VueRouter from 'vue-router'

// 引入登录路由配置文件
import Login from "@/router/Login";
// 引入home页面路由文件
import Home from "@/router/Home";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  // 登录路由配置对象
  Login,
  // home页面路由配置对象
  Home
]

const router = new VueRouter({
  routes
})

//  挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/login') return next()
  const tokenStr = window.sessionStorage.getItem('accessToken')
  if( to.fullPath==="/Recruitment" ){
    window.sessionStorage.setItem('Recruitment',true)
    return next()
  }
  if (!tokenStr) return next('/login')
  const Identity = window.sessionStorage.getItem('userIdentity')
// 商家登录
 if(Identity==1){
  if(to.fullPath==="/classify"||to.fullPath==="/index"||to.fullPath==="/updateBusinessInfo"||to.fullPath==="/merchantFormList"){
window.sessionStorage.setItem('Recruitment',"")
return next()
  }else{
    window.sessionStorage.setItem('Recruitment',"")
    return  next('/login')
  }
 }else if(Identity==10){
  window.sessionStorage.setItem('Recruitment',"")
  return  next()
 }else{
  window.sessionStorage.setItem('Recruitment',"")
  return  next('/login')
 }
return  next()
})

export default router

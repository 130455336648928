<template>
  <div class="login_container" >
    <div class="loginbox">
 
      <el-form   label-width="80px" class="login_form">
        
        <!--用户名-->
        <el-form-item label="" v-if="dunxin"  class="label-item-username"  >
          <el-input v-model="loginForm.userName"  placeholder="请输入账号" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <el-form-item label="" v-else class="label-item-username" >
          <el-input v-model="sendShopLog.mobile" placeholder="请输入手机号" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <!--密码-->
        <el-form-item v-if="dunxin" label="" class="label-item-password"  >
          <el-input  v-model="loginForm.password" placeholder="请输入密码" prefix-icon="el-icon-lock" type="password" @keypress.native.enter="login"></el-input>
        </el-form-item>
        <el-form-item v-else label=""  class="label-item-password"  >
          <el-input type="text" autocomplete="off" style="width: 160px; padding: 0  20px 0 0 ;"  placeholder="请输入验证码" v-model="sendShopLog.verificationCode"></el-input>
          <el-button
            type="primary"
            style="
              position: absolute;
              let: 0px;
              width: 80px;
              text-align: center;
              font-size: 12px;
              padding: 0;
              height: 40px;
            "
            @click="verificationnum"
            >{{ verification }}</el-button
          >
        </el-form-item>
        <!-- <p @click="Recruitment" class="pull-rightc">注册商家</p> -->
        <span class="span" @click="ondunxin" v-if="dunxin">短信验证登录</span>
        <span class="span" @click="ondunxin" v-else >密码验证登录</span>
        
     
        <!--登录按钮-->
        <el-form-item class="btns" v-if="dunxin">
          <el-button type="primary" round class="loginBtn" @click="login" :loading="isLoading">登录</el-button>
        </el-form-item>
         <el-form-item class="btns" v-else>
          <el-button
            type="primary"
            round
            class="loginBtn"
            @click="ShopMsgForH5"
            :loading="isLoading"
            >登录</el-button
          >
        </el-form-item>
        
      </el-form>
      
    </div>
    
  </div>
</template>

<script>
import {getUserSignInDetailList,sendShopLoginMsg,loginShopMsgForH5} from "@/api";

export default {
  name: "login",
  data() {
    return {
      sendShopLog:{
        mobile:"",
        verificationCode:""
      },
     
      dunxin:true,
      isMerchant:false,
        verification: "获取验证码",
      loginForm: {
      userName: '',
      password:'',
      },
      loginrules: {
      userName: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        verificationCode: [
          {required: true, message: '请输入管理员密码', trigger: 'blur'},
        ]
      },
      
      isLoading: false
    }
  },
  created(){

  },
  methods: {
    Recruitment(){
 this.$router.push('/Recruitment')
    },
    ondunxin(){
this.dunxin=!this.dunxin
    },
     // 获取验证码
    async verificationnum() {
      if (this.verification === "获取验证码") {
        const { data: res } = await sendShopLoginMsg({
         mobile: this.sendShopLog.mobile,
        });
        if (res.code != 200) return this.$message.error(res.msg);
        let down = 60;
        this.timer = setInterval(() => {
          if (down > 0) {
            --down;
            this.verification = `重新发送${down}S`;
          } else {
            this.verification = "获取验证码";
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 验证码登录
       async ShopMsgForH5() {
      const { data: res } = await loginShopMsgForH5(this.sendShopLog);
      if (res.code != 200) return this.$message.error(res.msg);
        window.sessionStorage.setItem('accessToken', res.body.accessToken)
        window.sessionStorage.setItem('userIdentity',res.body.userIdentity)
          window.sessionStorage.setItem('userId',res.body.id)
          window.sessionStorage.setItem('style', '')
          this.$router.push('/home')
        
 if (res.body.userIdentity!= 1&&res.body.userIdentity!=10) return this.$message.error("该账号不是平台号或商家号");
        this.$message.success('登录成功')
    },
  
    // 进行登录前的预校验
    async login() {
        this.isLoading = true
        const {data: res} = await getUserSignInDetailList(this.loginForm)
        this.isLoading = false
        if (res.code != 200) return this.$message.error(res.msg);
        window.sessionStorage.setItem('accessToken', res.body.accessToken)
        window.sessionStorage.setItem('userIdentity',10)
        window.sessionStorage.setItem('userId',res.body.id)
        window.sessionStorage.setItem('style', '')
        this.$router.push('/home')
 if (res.body.userIdentity!=1&&res.body.userIdentity!=10) return this.$message.error("该账号不是平台号或商家号");
        this.$message.success('登录成功')
   
      
    }
  }
}
</script>

<style scoped lang="scss">
.pull-rightc{
     font-size: 12px;
    text-decoration: none;
    cursor: pointer;
    color: #005980;
    float: left;
   margin: -10px  0 0 100px ;
}
input{
  color: #666;
}
.span{
    text-decoration: none;
    cursor: pointer;
    color: #005980;
    font-size: 12px;
    margin:-10px 0px 10px 0  ;
    float: right;
}
.loginbox  ::v-deep  .el-input__inner {
    background-color: #fff !important;
}
.login_container {
  background: url("http://qny.fhby.fuhangbeiyue.com/fhjt/image/1605092283168");
  background-position: 50%;
  background-size: cover;
  height: 100%;
  position: relative;
  width: 100%;

  .loginbox {
    width: 380px;
    height: 260px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    right: 120px;
    transform: translate(0, -50%);

    .login_form {
      position: absolute;
      top: 55%;
      left: 40%;
      transform: translate(-50%, -50%);
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
        -webkit-user-select:none;
    user-select:none;
      .btns {
        .loginBtn {
          width: 100%;
         margin:10px 0 0 0px ;
        }
      }
    }
  }
}

</style>


import Vue from 'vue'

import {
  Form,
  FormItem,
  Input,
  Button,
  Message,
  MessageBox,
  Container,
  Header,
  Main,
  Aside,
  Menu,
  MenuItem,
  MenuItemGroup,
  Submenu,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Row,
  Select,
  Option,
  Upload,
  Dialog,
  Radio,
  Switch,
  CheckboxGroup,
  RadioGroup,
  Checkbox,
  Col,
  DatePicker,
  TimePicker,
  Table,
  TableColumn,
  Tooltip,
  Pagination,
  Divider,
  Cascader,
  Avatar,
  Image,
  Backtop,
  Tag,
  Carousel,
  CarouselItem,
  Popover,
  TabPane,
  Tabs,
  Progress
} from "element-ui";
Vue.use(Progress)
Vue.use(TabPane)
Vue.use(Tabs)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tooltip)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Button)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Aside)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Select)
Vue.use(Option)
Vue.use(Upload)
Vue.use(Dialog)
Vue.use(Radio)
Vue.use(Switch)
Vue.use(RadioGroup)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Col)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Pagination)
Vue.use(Divider)
Vue.use(Cascader)
Vue.use(Avatar)
Vue.use(Image)
Vue.use(Backtop)
Vue.use(Tag)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Popover)

// 将弹窗组件挂在到vue原型上
// 提示弹窗
Vue.prototype.$message = Message
// 警告弹窗
Vue.prototype.$confirm = MessageBox.confirm

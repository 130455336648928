import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './utils/aixos'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入element.js
import '../plugins/element'
import VueQuillEditor from 'vue-quill-editor';
import * as Quill from 'quill'; //引入编辑器
// imageResize  首字母必须小写否则会报错，错误语如下
// quill Cannot import ImageResize. Are you sure it was registered?
import imageResize  from 'quill-image-resize-module'
Quill.register('modules/imageResize', imageResize )
import { ImageDrop } from 'quill-image-drop-module'
Quill.register('modules/imageDrop', ImageDrop)
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import XLSX from 'xlsx'

Vue.prototype.$XLSX = XLSX; //设置全局
Vue.use(VueQuillEditor);
import echarts from "echarts";
Vue.prototype.$echarts = echarts;
// 引入三级联动组件
import Distpicker from 'v-distpicker'
Vue.component('v-distpicker', Distpicker)
// 全局引入自定义样式
import "@/assets/css/global.css"
Vue.use(ElementUI);
Vue.config.productionTip = false
// 将axios实力对象挂载到vue原型
Vue.prototype.$http = http
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
